import React from 'react'

function TechConsult() {
  return (
    <div>TechConsult
        <h1>Techconsult</h1>
    </div>
  )
}

export default TechConsult